body {
    margin: 0;
}

.main-logo {
    margin-top: 0.5rem;
}

.list-wrapper {
    /* overflow: scroll;
    overflow-y: initial; */
}

.list-page {
    width: 100%;
}

.scrolling-grid {
    background: green;
}

@media only screen and (max-width: 768px) {
    .list-wrapper {
        display: flex;
        flex: 1 1 auto;
        overflow-y: hidden;
        overflow-x: scroll;
    }
}
