.SearchBar--container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.SearchBar--text {
  margin: 0 0.25rem 0 0;
  width: 100%;
}

.SearchBar--button {
}

.SearchBar--button button {
  background-color: #2185d0;
  border-radius: 0.28571429rem;
  color: #fff;
  padding: 0.78571429em 1.5em;
  outline: 0;
  border: none;
  min-height: 1em;
  font-family: "Nunito", "Lato", sans-serif;
  font-size: 0.86rem;
}

.SearchBar--form {
  display: flex;
  flex: 1;
  max-width: 100%;
  position: relative;
  font-size: 1rem;
  width: 500px;
}

/* Semantic Stuff */
.semantic--ui-fluid-input {
  display: flex;
  font-size: 1em;
  position: relative;
  font-weight: 400;
}

.semantic--ui-fluid-input input {
  width: 100%;
}

.semantic--ui-fluid-input input[type="text"] {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  tap-highlight-color: rgba(255, 255, 255, 0);
  line-height: 1.2142em;
  padding: 0.67861429em 1em;
  font-size: 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  -webkit-transition: color 0.1s ease, border-color 0.1s ease;
  transition: color 0.1s ease, border-color 0.1s ease;
}

.semantic--ui-primary-button {
}
