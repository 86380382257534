.demo-wrapper {
  width: 100% !important;
  display: block !important;
  height: 400px !important;
  margin: 5px 5px 25px 5px !important;
}
.demo-editor {
  height: 275px !important;
  border: 1px solid #F1F1F1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
  margin:5px;
}
.demo-content {
  border: 1px solid #F1F1F1;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  border-radius: 2px;
  resize: none;
  height: 200px;
  margin:5px;
}