.checkbox-list-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}

.checkbox-list-item {
    flex: 1 1 50%;
    display: flex;
}

@media only screen and (min-width: 769px) {
    .checkbox-list-item {
        flex: 1 1 50%;
        flex-direction: row;
        justify-content: flex-start;
    }
}
