.text-row {
    display: flex;
    flex-direction: column;
}

@media only screen and (min-width: 769px) {
    .text-row {
        display: flex;
        flex-direction: row;
    }
}
