.job-container {
  padding: 2rem 0;
}

.long-row {
  flex-direction: column;
}

@media only screen and (min-width: 1500px) {
  .long-row {
    flex-direction: row;
  }
}
