.card-actions {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 768px) {
  .card-actions {
    display: block;
    text-align: center;
  }
}
